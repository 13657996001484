import { Alert, Spin, Table } from "antd";
import { getDownloadUrl } from "../utilities/download";
import { parse } from 'csv-parse/sync';
import { useEffect, useState } from "react";
import { fetchWithTimeout } from "../utilities/utilities";
import "./Preview.css"
import { ICancellable, ISelection, NotificationCallback } from "../types";

export function Preview(props: { query: ISelection, token: string, notify: NotificationCallback }) {
  const downloadUrl = getDownloadUrl({ ...props.query, limit: 500 }, props.token);
  const [data, setData] = useState([] as object[]);
  const [columns, setColumns] = useState<{ title: string, dataIndex: string, key: string }[] | undefined>(undefined);
  enum StatusEnum { Loading, Ready, Error };
  const [status, setStatus] = useState<{status: StatusEnum, requestUUID: string|undefined}>({status: StatusEnum.Loading, requestUUID: ""});
  const [currentRequest, setCurrentRequest] = useState<ICancellable>()
  const [currentUUID, setCurrentUUID] = useState<string>()

  const fetch = async (requestUUID: string|undefined) => {
    try {
      setStatus({status: StatusEnum.Loading, requestUUID: requestUUID});

      // if there is an ongoing request then cancel it.
      if (currentRequest) {
        currentRequest.cancel();
      }

      const request = fetchWithTimeout(downloadUrl, props.notify, props.token);
      setCurrentRequest(request);
      const body = await request.fetch(r => r.text());

      const records: {info: {columns: [{name: string}]}, record: object}[] = parse(body, {
        columns: true,
        skip_empty_lines: true,
        info: true
      });

      if (records.length > 0) {
        const newColumns = records[0].info.columns.map(field => ({
          title: field.name,
          dataIndex: field.name,
          key: field.name
        }));

        const record_objects = records.map(r => r.record);
  
        setData(record_objects);
        setColumns(newColumns);
        setStatus({status: StatusEnum.Ready, requestUUID: requestUUID});
      }
      else {
        setData([]);
        setColumns([]);
        setStatus({status: StatusEnum.Ready, requestUUID: requestUUID});
      }      
    }
    catch (e) {
      console.log(e);
      setStatus({status: StatusEnum.Error, requestUUID: requestUUID});
    }
  };

  useEffect(() => {
    setCurrentUUID(crypto.randomUUID());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.query]);

  useEffect(() => {
    fetch(currentUUID);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUUID, props.notify]);
  
  if (currentUUID !== status.requestUUID || status.status === StatusEnum.Loading) {
    return <div >
        <Spin tip="Loading" size="large" style={{marginTop: "5%"}}>
            <div className="content" />
        </Spin>
      </div>;
  }
  else if (status.status === StatusEnum.Ready) {
    return <>
      <Table scroll={{x: true}} bordered={true} dataSource={data} columns={columns} className="table-striped-rows"/>
      </>;
  }
  else if (status.status === StatusEnum.Error) {
    return <Alert
          message="Error"
          description="An error occurred loading the page."
          type="error"
          showIcon
        />
  }
  else {
    return <div>unexpected status: {StatusEnum[status.status]}</div>;
  }
}