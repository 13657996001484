import { Form, Space, Switch } from 'antd';
import { PrimaryColor } from '../theme';
import { DataNode } from 'antd/es/tree';
import { dictToArray, groupBy } from '../utilities/utilities';
import TreeSelect from 'antd/es/tree-select';
import { setFacetValues } from './FacetSelector';
import { IFacet, ISelection } from '../types';
const { SHOW_PARENT } = TreeSelect;


export function TreeFacetSelector(
    props: { 
        facet: IFacet; 
        selection: ISelection; 
        setSelection: (selection: ISelection) => void; 
        values:string[]; 
        setValues: (values: string[]) => void; 
        expandedKeys:React.Key[]; 
        setExpandedKeys: (values: React.Key[]) => void; 
    }) {

    const value = props.values

    // gracefully handle missing facet without completely breaking UI
    if (!props.facet) {
        return <Form.Item
            key='missing'
            label='missing'
            name='missing'
            rules={[{ required: false }]}>
            <Space direction="vertical" style={{ display: 'flex', marginTop: 10 }}>
                Missing Facet
            </Space>
        </Form.Item>;
    }

    const filterForFacet = props.selection.filters.filter(f => f.facet === props.facet.code)[0];
    const summarize = filterForFacet && filterForFacet.summarize;

    const onChangeSummary = (checked: boolean, event: React.MouseEvent<HTMLButtonElement>) => {

        let filterForSummarizeFacet = props.selection.filters.filter(f => f.facet === props.facet.code);

        if (filterForSummarizeFacet.length === 0) {
            filterForSummarizeFacet = [{ facet: props.facet.code, summarize: checked, values: [] }];
        }
        else {
            filterForSummarizeFacet[0].summarize = checked;
        }

        const otherFilters = props.selection.filters.filter(f => f.facet !== props.facet.code);

        otherFilters.push(...filterForSummarizeFacet);

        const newSelection = {
            ...props.selection,
            filters: otherFilters
        };

        props.setSelection(newSelection);
    };


    let treeData: DataNode[] = buildTree(props);

    const getValues = (values: string[], prefix: string) => values.filter(v => v.startsWith(prefix)).map(v => v.substring(prefix.length));

    const onChange2 = (newValue: string[]) => {

        props.setValues(newValue);

        setFacetValues(props.selection, props.setSelection)([
            { facet_code: "Mode", values: getValues(newValue, "MODE:") },
            { facet_code: "ModalSubgroup", values: getValues(newValue, "SUBGROUP:") },
            { facet_code: "Chapter", values: getValues(newValue, "CH:") },
            { facet_code: "Section", values: getValues(newValue, "S:") },
            { facet_code: "hs4", values: getValues(newValue, "SG_HS4:").concat(getValues(newValue, "HS4:")) },
        ]);

    };

    const tProps = {
        treeData,
        treeExpandedKeys: props.expandedKeys,
        allowClear: true,
        value,
        onChange: onChange2,
        treeCheckable: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: 'Please select',
        listHeight: 600,
        style: {
            width: '100%'
        }
    };

    const treeSelector = <TreeSelect
        filterTreeNode={(inputValue, treeNode) => {  
            return treeNode.title?.toString().toLowerCase().indexOf(inputValue.toLowerCase()) !== -1; 
        }}
        onTreeExpand={props.setExpandedKeys}
        dropdownMatchSelectWidth={1000}
        {...tProps} />;  

    return <Form.Item
        key={props.facet.code}
        name={props.facet.code}
        rules={[{ required: false }]}>
        <Space direction="vertical" style={{ display: 'flex', marginTop: 10 }}>
            <div>
                {props.facet.name}:
                {!summarize && treeSelector}
                {props.facet.showSummarize &&
                <Switch
                    style={{ backgroundColor: summarize ? PrimaryColor : '' }}
                    checkedChildren="Filter"
                    unCheckedChildren="Aggregate"
                    onChange={onChangeSummary}
                    checked={summarize} />}
            </div>
        </Space> 
    </Form.Item>;
}

function buildTree(props: { facet: IFacet; selection: ISelection; setSelection: (selection: ISelection) => void; }) {

    if (props.facet.code !== "hs4") {
        return [];
    }

    const data = props.facet.options;

    const hs4BySection = groupBy(data, section => section.metadata["Section"]);
    const sections = dictToArray(hs4BySection);

    const sectionByChapter = groupBy(sections, section => section.value[0].metadata["Chapter"]);
    const chapters = dictToArray(sectionByChapter);

    const chapterTreeData = chapters.map(chapter => ({
        title: chapter.key ?? "Other",
        value: 'CH:' + chapter.key,
        key: 'CH:' + chapter.key,
        children: chapter.value.map(section => ({
            title: section.key ?? "Other",
            key: 'S:' + section.key,
            value: 'S:' + section.key,
            children: section.value.map(hs4 => ({
                title: hs4.name,
                key: "HS4:" + hs4.code,
                value: "HS4:" + hs4.code,
                children: []
            }))
        }))
    }));

    const hs4ByModalSubgroup = groupBy(data, subgroup => subgroup.metadata["ModalSubgroup"]);
    const modalSubgroups = dictToArray(hs4ByModalSubgroup);

    const subgroupByMode = groupBy(modalSubgroups, subgroup => subgroup.value[0].metadata["Mode"]);
    const modes = dictToArray(subgroupByMode);

    const modeTreeData = modes.map(mode => ({
        title: mode.key ?? "Other",
        value: 'MODE:' + mode.key,
        key: 'MODE:' + mode.key,
        children: mode.value.map(subgroup => ({
            title: subgroup.key ?? "Other",
            key: 'SUBGROUP:' + subgroup.key,
            value: 'SUBGROUP:' + subgroup.key,
            children: subgroup.value.map(hs4 => ({
                title: "[" + hs4.code + "] " + hs4.name,
                key: 'SG_HS4:' + hs4.code,
                value: 'SG_HS4:' + hs4.code,
                children: []
            }))
        }))
    }));

    let treeData: DataNode[] = [
        {
            title: "By Mode",
            value: 'BYMODE',
            key: 'BYMODE',
            children: modeTreeData
        },
        {
            title: "By Chapter",
            value: 'BYCHAPTER',
            key: 'BYCHAPTER',
            children: chapterTreeData
        }
    ] as any[];

    // by chapter branch seems a bit messy
    // keeping this code in place temporarily but should be removed
    // when mode tree is confirmed.
    if (true) {
        return modeTreeData;
    }
    else {
        return treeData;
    }
}

