import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  SelectableOptionMenuItemType,
  Spinner,
} from "@fluentui/react";

import useCancelableRequest from "../utilities/useCancelableRequest";
import { FormEvent, useCallback, useRef, useState } from "react";
import { IFacet, ISelection } from "../types";
import { useDebounce } from "@uidotdev/usehooks";

interface DynamicFacetProps {
  facet: IFacet;
  label: string;
  url: string;
  selection: ISelection;
  setSelection: (selection: ISelection) => void;
  setFacetValues: (
    selection: ISelection,
    setSelection: (selection: ISelection) => void,
  ) => (
    selectedFacets: {
      facet_code: string;
      values: string[];
    }[],
  ) => void;
}

/**
 * Returns a list of options for the dynamic facet field. Separates them into 2
 * sub-lists - "Selected" and "More Options"
 */
function hydrateOptions(fetchedOptions: string[], selectedOptions: string[]) {
  let options: IComboBoxOption[] = [];

  // create a "selected" sub-list if one or more "selected" option is present
  if (selectedOptions.length > 0) {
    const selectedHeader = {
      key: "Selected",
      text: "Selected",
      itemType: SelectableOptionMenuItemType.Header,
    };
    const formattedSelectedOptions = selectedOptions.map((d) => ({
      key: d,
      text: d,
    }));
    options = [selectedHeader, ...formattedSelectedOptions];
  }

  // create a "more options" sub-list if more options can be selected
  if (fetchedOptions.length > 0) {
    // add a "More Options" sub-list header if the first sub-list is present
    if (selectedOptions.length > 0) {
      const moreOptionsHeader = {
        key: "More",
        text: "More Options",
        itemType: SelectableOptionMenuItemType.Header,
      };
      options.push(moreOptionsHeader);
    }

    const formattedFetchedOptions = fetchedOptions
      // filter out any options present in the "selected" list & format into key-text structure
      .filter((o) => !options.find(({ key }) => key === o))
      .map((d) => ({ key: d, text: d }));

    options = [...options, ...formattedFetchedOptions];
  }
  return options;
}

/**
 * Wait for 1s after the user types the last character.
 * Then return the final value as the request URL.
 */
function useDebounceRequestUrl(url: string, term: string) {
  const newUrl = term.length > 0 ? url.replace("{term}", term) : null;
  const oneSec = 1000; // 1s = 1000ms
  const zeroSec = 0;
  const debounceTime = newUrl ? oneSec : zeroSec; // don't wait if new value is empty.
  return useDebounce(newUrl, debounceTime);
}

/**
 * Displays a ComboBox, allowing the user to set a dynamic facet filter.
 * Only one value can be chosen, free form input is allowed (but is restricted to
 * one of the available options when the user hits "Enter")
 */
export default function DynamicFacet({
  facet,
  label,
  url,
  selection,
  setSelection,
  setFacetValues,
}: DynamicFacetProps) {
  const comboBoxRef = useRef<IComboBox>(null);
  const openOptions = useCallback(() => comboBoxRef.current?.focus(true), []);
  const [term, setTerm] = useState("");

  const requestUrl = useDebounceRequestUrl(url, term);

  const { data, isLoading, requestError } =
    useCancelableRequest<string[]>(requestUrl);

  const selectedOptions =
    selection.filters.find((f) => f.facet === facet.name)?.values || [];

  const handleValueChange = (value: string) => {
    setTerm(value ?? "");
  };

  const handleChange = (
    event: FormEvent<IComboBox>,
    option?: IComboBoxOption,
  ) => {
    if (!option) {
      return;
    }
    // add or remove the option clicked, depending on whether it's already in the list
    const values = !selectedOptions.find((o) => o === option.text)
      ? [...selectedOptions, option.text]
      : selectedOptions.filter((o) => o !== option.text);
    setFacetValues(
      selection,
      setSelection,
    )([{ facet_code: facet.code, values }]);
  };

  const fetchedOptions = data || [];
  const options = hydrateOptions(fetchedOptions, selectedOptions);

  const dropdownEnabled = term && term.length > 0;

  // TODO: implement a "clear all" button when this is resolved
  // https://github.com/microsoft/fluentui/issues/28160

  return (
    <div
      style={{
        position: "relative",
        maxHeight: "inherit",
        display: "flex",
        flexFlow: "row nowrap",
        alignItems: "flex-end",
      }}
    >
      <ComboBox
        selectedKey={selectedOptions}
        autoComplete={"off"}
        componentRef={comboBoxRef}
        onClick={openOptions}
        onChange={handleChange}
        label={label}
        options={options}
        allowFreeInput={options.length === 0}
        allowFreeform={true}
        calloutProps={{ doNotLayer: true }}
        onInputValueChange={handleValueChange}
        errorMessage={requestError ? requestError.message : undefined}
        iconButtonProps={{
          disabled: !dropdownEnabled,
          style: { cursor: dropdownEnabled ? "pointer" : "not-allowed" },
        }}
        useComboBoxAsMenuWidth
        multiSelect
      />
      {isLoading && (
        <Spinner style={{ position: "absolute", left: "-16%", top: "58%" }} />
      )}
    </div>
  );
}
