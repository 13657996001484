import { MessageBar, MessageBarType } from "@fluentui/react";
import { IFacet, ISelection, Option } from "../types";
import { getFilterUrlTemplate } from "../utilities/download";
import DateRangeFacet from "./DateRangeFacet";
import DynamicFacet from "./DynamicFacet";
import StaticFacet from "./StaticFacet";

/**
 * Checks the data of the current facet and renders one of 3 facet pickers,
 * depending on the type of the facet provided.
 */
export default function FacetField({facet, selection, setSelection, setFacetValues, token, isMulti, selectedOptions, filteredOptions, lastMonth, now}: {facet: IFacet;selection: ISelection,
    setSelection: (selection: ISelection) => void, token: string | undefined; isMulti?: boolean; selectedOptions: Option[]; filteredOptions: Option[]; lastMonth: Date, now: Date; setFacetValues: (selection: ISelection, setSelection: (selection: ISelection) => void) => (selectedFacets: {
        facet_code: string;
        values: string[];
    }[]) => void }){
    switch(facet.type) {
        case 'Dynamic' :
            return <DynamicFacet
                facet={facet}
                label={facet.name}
                url={getFilterUrlTemplate(facet.code, token || '')}
                selection={selection}
                setSelection={setSelection}
                setFacetValues={setFacetValues} />
                
        case 'Static' :
            return <StaticFacet facet={facet} selection={selection} setSelection={setSelection} setFacetValues={setFacetValues} selectedOptions={selectedOptions} filteredOptions={filteredOptions} isMulti={isMulti}/>
        case 'DateRange' :
                return <DateRangeFacet startDate={lastMonth} endDate={now} selection={selection}
                setSelection={setSelection}
                setFacetValues={setFacetValues} facet={facet} />
        default: 
            return <MessageBar isMultiline messageBarType={MessageBarType.warning}>
            Incorrect facet type declared in data source: "{facet.type}"
            {facet.name} - {facet.code}
          </MessageBar>;
    }
}