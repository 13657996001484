import { MessageBar, MessageBarType, Stack, Toggle } from "@fluentui/react";
import { IconButton } from "@fluentui/react/lib/Button";
import { TooltipDelay, TooltipHost } from "@fluentui/react/lib/Tooltip";
import React from 'react';
import { IFacet, IFilter, ISelection} from "../types";
import FacetField from "./FacetField";

interface IFacetSelectorProps {
    facet: IFacet,
    selection: ISelection,
    setSelection: (selection: ISelection) => void,
    toolTipTitle?: JSX.Element | string,
    isMulti?: boolean;
    token?: string;
}

/**
 * Renders a generic facet selector field, which includes an "i" icon, a tooltip
 * with the facet name when you hover over the icon, the facet field itself (<FacetField />)
 * and a summary (if provided)
 */
export function FacetSelector({facet, selection, setSelection, toolTipTitle, isMulti, token}: IFacetSelectorProps) {

    const nowDate = new Date();
    const lastMonth = new Date(new Date().setMonth(nowDate.getMonth()-1));

    const filterForFacet = selection.filters.filter(f => f.facet === facet.code)[0];
    const selectedOptions = getSelectedOptions(facet, filterForFacet, selection);
    const summarize = filterForFacet && filterForFacet.summarize;
    const filteredOptions = getFilteredOptions(facet, selection);

    const onChangeSummary = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {

        let filterForSummarizeFacet = selection.filters.filter(f => f.facet === facet.code);

        if (filterForSummarizeFacet.length === 0) {
            filterForSummarizeFacet = [{ facet: facet.code, summarize: !!checked, values: [] }];
        }
        else {
            filterForSummarizeFacet[0].summarize = !!checked;
        }

        const otherFilters = selection.filters.filter(f => f.facet !== facet.code);

        otherFilters.push(...filterForSummarizeFacet);

        const newSelection = {
            ...selection,
            filters: otherFilters
        };

        setSelection(newSelection);
    }

    // gracefully handle missing facet without completely breaking UI
    if (!facet) {
        return <MessageBar messageBarType={MessageBarType.warning}>
                  Missing Facet
                </MessageBar>;
    }
    
    return <Stack
        horizontal 
        styles={{root: {width: '35rem'}}}
        tokens={{
        childrenGap: 10,
        padding: 10,      
    }}>
    <TooltipHost
      content={toolTipTitle}
      id={`toolTipId_${facet.code}`}
      delay={TooltipDelay.zero}
      calloutProps={{ gapSpace: 0 }}
      styles={{ root: { display: "inline-block" } }}
    >
      <IconButton iconProps={{ iconName: "Info" }} aria-describedby={`toolTipId_${facet.code}`} />
    </TooltipHost>    
    <FacetField facet={facet} token={token} isMulti={isMulti} selectedOptions={selectedOptions} filteredOptions={filteredOptions} lastMonth={lastMonth} now={nowDate} selection={selection} setSelection={setSelection} setFacetValues={setFacetValues}/>
    {summarize && <Toggle label="Aggregate" onText="On" offText="Off" onChange={onChangeSummary} />}
  </Stack>;
}

export const getFilteredOptions = (facet: IFacet, selection: ISelection) => {
    const options = facet.options.map(i => ({ value: i.code, label: i.name }));

    let filteredOptions = options;

    if (facet.code === "destination") {
        const origins = selection.filters.find(x => x.facet === "origin")?.values;
        if (origins !== undefined && origins?.length === 1) {
            const origin = origins[0];
            filteredOptions = options.filter(x => x.value !== origin)
        }
    }

    if (facet.code === "origin") {
        const destinations = selection.filters.find(x => x.facet === "destination")?.values;
        if (destinations !== undefined && destinations?.length === 1) {
            const destination = destinations[0];
            filteredOptions = options.filter(x => x.value !== destination)
        }
    }

    return filteredOptions;
}

export const setFacetValues = (selection: ISelection,
    setSelection: (selection: ISelection) => void) => (selectedFacets: { facet_code: string; values: string[]; }[]) => {

    let filters = selection.filters;

    selectedFacets.forEach(({ facet_code, values }) => {

        let currentFilter = filters.filter(f => f.facet === facet_code);

        if (currentFilter.length === 0) {
            currentFilter = [{ facet: facet_code, values: values, summarize: false }];
        }
        else {
            currentFilter[0].values = values;
        }

        filters = filters
            .filter(f => f.facet !== facet_code)
            .concat(currentFilter);
    });

    setSelection({
        ...selection,
        filters: filters
    });

};

export const getSelectedOptions = (
    facet: IFacet,
    filterForFacet: IFilter,
    selection: ISelection
) => {
    if (facet.code === "transformation")
    {
        return selection.transformations.map(
                transformation => ({
                    value: transformation, 
                    label: facet.options.filter(option => option.code === transformation)[0].name
                }));
    }

    if (facet.code === "indicator")
    {
        return selection.indicators.map(
                indicator => ({ 
                    value: indicator, 
                    label: facet.options.filter(option => option.code === indicator)[0].name
                }));
    }

    if (!filterForFacet || filterForFacet.values.length <= 0) { return []; }

    return facet.options
        .filter(option => filterForFacet.values.includes(option.code))
        .map(option => ({ value: option.code, label: option.name }));
}
