import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  SelectableOptionMenuItemType,
} from "@fluentui/react";
import {
  FacetsEnum,
  IFacet,
  ISelection,
  Option,
} from "../types";
import { labelSort } from "../utilities/utilities";
import { useState } from "react";

interface StaticFacetProps {
  facet: IFacet;selection: ISelection,
  setSelection: (selection: ISelection) => void, isMulti?: boolean; selectedOptions: Option[]; filteredOptions: Option[]; setFacetValues: (selection: ISelection, setSelection: (selection: ISelection) => void) => (selectedFacets: {
      facet_code: string;
      values: string[];
  }[]) => void }

enum StaticOptions {
  SelectAll = "selectAll",
  Header = "header"
}

/**
 * Returns a sorted array of facet option fields. Includes the "header"
 * and "selectAll" items.
 */
function getOptions(filteredOptions: Option[], selectAll: boolean) {
  const options: IComboBoxOption[] = filteredOptions
    .sort(labelSort)
    .map((o) => ({ key: o.value, text: o.label }));
  options.unshift(
    {
      key: StaticOptions.SelectAll,
      text: "Select All",
      itemType: SelectableOptionMenuItemType.SelectAll,
      selected: selectAll,
    },
    {
      key: StaticOptions.Header,
      text: "Options",
      itemType: SelectableOptionMenuItemType.Header,
    },
  );
  return options;
}

/**
 * Returns the selection of facet options after a new selection has been made.
 * Takes into account the "select all" flag.
 */
function getNewSelection(
  facet: IFacet,
  selection: ISelection,
  option: IComboBoxOption,
  options: IComboBoxOption[],
  selectAll: boolean,
) {
  const currentSelection =
    selection.filters.find((f) => f.facet === facet.code)?.values || [];
  if (
    option.itemType === SelectableOptionMenuItemType.SelectAll &&
    !selectAll
  ) {
    return options
      .filter((o) => o.key !== "selectAll" && o.key !== "header")
      .map((o) => String(o.key));
  }
  if (option.itemType === SelectableOptionMenuItemType.SelectAll && selectAll) {
    return [];
  }
  return option.selected &&
    option.itemType !== SelectableOptionMenuItemType.SelectAll
    ? [...currentSelection, option.key.toString()]
    : currentSelection.filter((v) => v !== option.key.toString());
}

/**
 * Displays a ComboBox, allowing the user to set a static facet filter.
 * Multiple values can be chosen, free form input is allowed (but will resolve to
 * one of the available options when the user hits "Enter")
 */
export default function StaticFacet({
  facet,
  selection,
  setSelection,
  setFacetValues,
  isMulti,
  selectedOptions,
  filteredOptions,
}: StaticFacetProps) {
  const [selectAll, setSelectAll] = useState(false);
  const options = getOptions(filteredOptions, selectAll);

  const handleChange = (
    e: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
  ) => {
    if (!option) return;

    if (option.itemType === SelectableOptionMenuItemType.SelectAll) {
      setSelectAll(!selectAll);
    }

    const newSelection = getNewSelection(
      facet,
      selection,
      option,
      options,
      selectAll,
    );

    if (options.length - 2 === newSelection.length) {
      setSelectAll(true);
    }

    if (newSelection.length === 0) {
      setSelectAll(false);
    }

    if (facet.code === FacetsEnum.Transformation) {
      setSelection({
        ...selection,
        transformations: newSelection,
      });
    } else if (facet.code === FacetsEnum.Indicator) {
      setSelection({
        ...selection,
        indicators: newSelection,
      });
    } else {
      setFacetValues(
        selection,
        setSelection,
      )([{ facet_code: facet.code, values: newSelection }]);
    }
  };

  return (
    <ComboBox
      multiSelect={isMulti}
      selectedKey={selectedOptions.map((o) => o.value)}
      label={facet.name}
      allowFreeInput
      allowFreeform
      options={options}
      styles={{ root: { maxWidth: 500, width: "30rem" } }}
      onChange={handleChange}
    />
  );
}
