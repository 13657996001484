import { useEffect, useState } from "react";
import axios, { CancelTokenSource } from "axios";

async function getResponse<T>(
  url: string,
  source: CancelTokenSource,
  setIsLoadingTrue: () => void,
) {
  // make sure the "setIsLoadingTrue" is always called before the axios.get()
  setIsLoadingTrue();
  return axios.get<T>(url, {
    cancelToken: source.token,
  });
}

/**A hook to cancel and retry a get request within a component.
 * Accepts null to not make a request.
 * Used when making many request to idempotent services */
export default function useCancelableRequest<T>(url: string | null) {
  const [data, setData] = useState<T>();
  const [isLoading, setIsLoading] = useState(true);
  const [requestError, setError] = useState<Error | null>(null);

  const setIsLoadingTrue = () => {
    setIsLoading(true);
  };

  useEffect(() => {
    setError(null);
    async function getRequest(url: string) {
      const source = axios.CancelToken.source();
      const response = await getResponse<T>(url, source, setIsLoadingTrue);
      setData(response.data);
      setIsLoading(false);
    }
    if (url) {
      try {
        getRequest(url);
      } catch (e) {
        setError(e as Error);
      }
    } else {
      setData(undefined);
      setIsLoading(false);
    }
  }, [url]);

  return { data, isLoading, requestError };
}
