import config from "../config.json";
import { encode } from "base-64";
import { ISelection } from "../types";
import { encode as utf8Encode } from "utf8";

export function getDownloadUrl(query: ISelection, token: string) {
  const queryBase64 = encode(
    utf8Encode(JSON.stringify(clearAggregateFilterValuesFromQuery(query))),
  );

  const url = `${config.apiBaseUrl}/api/tradeprism/v1/download/`;
  const downloadUrl = url + queryBase64 + "?token=" + token;

  return downloadUrl;
}

/** Gets a url template for searching values of a field  */
export function getFilterUrlTemplate(name: string, token: string) {
  return `${config.apiBaseUrl}/api/tradeprism/v1/dynamic_filter_options/${name}/{term}?token=${token}`;
}

function clearAggregateFilterValuesFromQuery(query: ISelection) {
  return {
    ...query,
    filters: query.filters.map((filter) => ({
      ...filter,
      values:
        (// I think we have a disconnect from the selection filters and commodity_aggregate facet options
        // the values don't align so I had to do this magic string stuff here.
        (filter.facet === "hs4" ||
          filter.facet === "ModalSubgroup" ||
          filter.facet === "Mode") &&
          queryHasCommoditionAggregateFilter(query)) ||
        filter.summarize
          ? []
          : filter.values,
    })),
  };
}

export function queryHasCommoditionAggregateFilter(query: ISelection) {
  return (
    query.filters.find((x) => x.facet === "commodity_aggregate") &&
    query.filters.find((x) => x.facet === "commodity_aggregate")!.values
      .length > 0
  );
}
