import React, { useEffect, useState } from 'react';

import { Alert, Layout, Space, Spin, ConfigProvider } from 'antd';
import 'antd/dist/reset.css';

import './App.css';
import { Content } from 'antd/es/layout/layout';
import { fetchWithTimeout } from './utilities/utilities';
import { MainControls } from './components/MainControls';
import config from './config.json'
import queryString from 'query-string';
import { BackgroundColor, Font } from './theme'

import { notification } from 'antd';
import { Facet, IFacet, NotificationCallback } from './types';
import { initializeIcons } from '@fluentui/react';

enum StatusEnum { Loading, Ready, Error };

const contentStyle: React.CSSProperties = {
  color: '#000',
  backgroundColor: BackgroundColor,
  font: Font,
  height: '100vh'
};

function App() {
  // TODO: refactor the app to use .env files instead of config.json
  document.title=config.pageTitle;
  return <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
    <Layout>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: Font,
          },
        }}
      >
        <Content style={contentStyle}>
          <AppConfig></AppConfig>
        </Content>
      </ConfigProvider>
    </Layout>

  </Space>
}

function AppConfig() {
  
  const [api, contextHolder] = notification.useNotification();    

  const showNotification = (message: string) => {
        
      api.info({
        message: message,        
        placement: 'topRight',
      });      
  };

  const parsed = queryString.parse(window.location.search);

  var idToken = parsed.idToken;  

  if (idToken && typeof(idToken) === 'string') {
      return <>
          {contextHolder}
          <AppContent token={idToken} notify={showNotification}></AppContent>                
      </>    
  }
  else {
    const loginUrl = `${config.loginBaseUrl}/account/signIn?returnUrl=${window.location}&returnToken=true`;

    window.location.assign(loginUrl);

    return <>Redirecting to authenticate</>
  }
}


function AppContent(props: { token: string, notify: NotificationCallback }) {
  const [status, setStatus] = useState<StatusEnum>(StatusEnum.Loading);
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [facets, setFacets] = useState<IFacet[]>([]);

  initializeIcons();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const request = fetchWithTimeout(`${config.apiBaseUrl}/api/tradeprism/v1/filter_options?token=${props.token}`, props.notify, props.token);
        const data: IFacet[] = await request.fetch(r => r.json());
        
        data.forEach(facet => {
          facet.showSummarize = !["indicator", "transformation", "hs4"].includes(facet.code);
        });

        let commodityAggregateOption : IFacet = new Facet();
        commodityAggregateOption.code = "commodity_aggregate";
        commodityAggregateOption.name = "Aggregate";
        commodityAggregateOption.options = [
            {code: "hs4", name: "HS4", metadata: {}},
            {code: "mode", name: "Mode", metadata: {}},
            {code: "modalsubgroup", name: "Modal subgroup", metadata: {}},
          ];

        data.push(commodityAggregateOption);
  
        setFacets(data);
        setStatus(StatusEnum.Ready);
      }
      catch (e: unknown) {
        console.log(e);
        setStatus(StatusEnum.Error);

        if (e instanceof Error) {
          setStatusMessage(e.message);
        }
        else {
          setStatusMessage("An error occurred loading the application.");
        }
      }
    }

    fetchUserData()
  }, [props.token, props.notify]);

  switch(status) {
    case StatusEnum.Loading:
      return <div className="App">
        {status === StatusEnum.Loading && <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>}
      </div>;
    case StatusEnum.Error:
      return <div className="App"><Alert
          message="Error"
          description={statusMessage}
          type="error"
          showIcon
        /></div>;
    case StatusEnum.Ready:
      return (
        <div className="App">      
            <MainControls facets={facets} apiKey={props.token} notify={props.notify} downloadLimit={config.downloadLimit}></MainControls>
        </div>
      );
    default:
      const x: never = status;

      return <div className="App">      
         <Alert
          message="Error"
          description={"The page entered an unexpected state: " + x}
          type="error"
          showIcon
        />
      </div>;
  }  
}

export default App;
