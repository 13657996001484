import { SelectableOptionMenuItemType } from "@fluentui/react";

export type FacetType = 'Static' | 'Dynamic' | 'DateRange';

export interface IFacet {
  code: string;
  name: string;
  type: FacetType;
  showSummarize: boolean;
  options: {
    code: string;
    name: string;
    metadata: { [key: string]: string };
  }[];
}

export class Facet implements IFacet {
  code: string = '';
  name: string = '';
  showSummarize: boolean = false;
  type: FacetType = 'Static';
  options!: {
    code: string;
    name: string;
    metadata: { [key: string]: string };
  }[];
}

export interface IFilter {
  facet: string;
  values: string[];
  summarize: boolean;
}

export interface ISelection {
  readonly startYear: number;
  readonly endYear: number;
  readonly indicators: string[];
  readonly transformations: string[];
  readonly filters: IFilter[];
  readonly limit: number;
  readonly compression: string;
}


export enum FacetsEnum {
    Transformation = "transformation",
    Indicator = "indicator",
}

export interface Option {
    value: string;
    label: string;
    itemType?: SelectableOptionMenuItemType
}

export type NotificationCallback = (message: string) => void;

  export interface ICancellable {
    fetch<T>(c: (response: Response) => Promise<T>): Promise<T>;
    cancel(): void;
  }