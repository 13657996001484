import config from "../config.json";
import { ICancellable, NotificationCallback } from "../types";

export class CancellableRequest implements ICancellable
{
  private readonly controller: AbortController;
  private completed: boolean;

  constructor(private resource: RequestInfo | URL, private options: RequestInit & { timeout: number }) {
    this.completed = false;
    this.controller = new AbortController();
  }

  public async fetch<T>(c: (response: Response) => Promise<T>) {
    
    const { timeout } = this.options;
    const id = setTimeout(() => this.controller.abort(), timeout);
    const response = await fetch(this.resource, {
      ...this.options,
      signal: this.controller.signal,
    });
    
    if (response.status !== 200) {
      throw Error("Non success status code retrieving: " + response.status);
    }
    
    const result = await c(response);

    clearTimeout(id);
    this.completed = true;
    
    return result;
  }

  public cancel() {
    if (!this.completed) {
      console.debug("Cancelling request: " + this.resource);
      this.controller.abort();
    }
  }
}

let lastProbeDate = new Date(0);

export function fetchWithTimeout(resource: any, notify: NotificationCallback, token: string, options: RequestInit & { timeout: number } = { timeout: 90000 }) {

  const currentDate = new Date();
  const elapsed = currentDate.getTime() - lastProbeDate.getTime();

  // this is assuming that the database idle time is at least 10 minutes
  if (token && elapsed > 10 * 60 * 1000) {

    // assumption is if the count endpoint takes longer than 5 seconds then it's been idling and is starting up.
    // (this only applies to clickhouse)
    const probe = new CancellableRequest(`${config.apiBaseUrl}/api/tradeprism/v1/count?token=${token}`, { ...options, timeout: 5000 });

    // fire and forget probe query to discover if the database is starting up
    probe.fetch(response => {
      return Promise.resolve();
    })
      .catch(reason => {
        if (reason instanceof Error && reason.name === "AbortError") {
          notify("Please wait, the database is starting... this should only take a minute.");
        }
      });

    lastProbeDate = currentDate;
  }

  return new CancellableRequest(resource, options);
}

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
//export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
//    const map = new Map<K, Array<V>>();
export function groupBy<T>(list: T[], keyGetter: (item: T) => string) {
  const map = new Map<string, T[]>();
  list.forEach((item) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map;
}

export function dictToArray<T1, T2>(map:Map<T1, T2>) {
  return Array.from(map.keys()).sort().map(key => ({key, value: map.get(key)!}));
}

export const labelSort = (a: { value: string; label: string; }, b: { value: string; label: string; }) => {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
}