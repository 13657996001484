import { DatePicker, Stack } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { IFacet, ISelection } from '../types';

interface DateRangeFacetProps {
  facet: IFacet,
  startDate: Date | undefined;
  endDate: Date | undefined;
  selection: ISelection;
  setSelection: (selection: ISelection) => void;
  setFacetValues: (selection: ISelection, setSelection: (selection: ISelection) => void) => (selectedFacets: {
      facet_code: string;
      values: string[];
  }[]) => void
}

/**
 * Displays 2 date pickers - "start" and "end" dates. These will determine the
 * time frame of the relevant data.
 */
export default function DateRangeFacet({facet, startDate,
  endDate,
  selection,
  setSelection,
  setFacetValues}: DateRangeFacetProps){
  const [dates, setDates] = useState({
    startDate,
    endDate
  });

  const day = (1 * 60 * 60 * 24 - 1) * 1000 // 23 hours, 59 minutes, 59 seconds

  // coerce the final dates (start & end) to compensate out the timezone offset,
  // because the dates being provisioned by FluentUI date picker are not in UTC
  const handleDateRangeSelect = (startDate: string | number | Date, endDate: string | number | Date) => {        
    const s = new Date(startDate);
    const start = new Date(s.getTime() - s.getTimezoneOffset() * 1000 * 60)
    const e = new Date(endDate);
    const end = new Date(e.getTime() - e.getTimezoneOffset() * 1000 * 60 + day); // include the whole last day, all the way to 23:59:59
    setFacetValues(selection, setSelection)([{ facet_code: facet.code, values: [start.toISOString(), end.toISOString()] }]);
}


  useEffect(() => {
    if(startDate && endDate){
      handleDateRangeSelect(startDate, endDate);
    }
}, [])

  return (
    <Stack horizontal styles={{ root: { maxWidth: '500px', width: '30rem' } }}>
      <Stack styles={{ root: { width: '15rem' } }}>
        <DatePicker
          styles={{ root: { width: '200px' } }}
          value={dates.startDate}
          label="From"
          onSelectDate={function (date) {
            if (date) {
              setDates({ ...dates, startDate: date });
              dates.endDate && handleDateRangeSelect(date, dates.endDate);
            }
          }}
        />
      </Stack>
      <Stack styles={{ root: { alignItems: 'right' } }}>
        <DatePicker
          styles={{ root: { width: '200px' } }}
          value={dates.endDate}
          label="To"
          onSelectDate={function (date) {
            if (date) {
              setDates({ ...dates, endDate: date });
              dates.startDate && handleDateRangeSelect(dates.startDate, date);
            }
          }}
        />
      </Stack>
    </Stack>
  );
};
